.App {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-container {
  padding: 20px;
  margin: auto;
  width: calc(100vw - 270px);
}

a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
